const urlMode = 'list'; // 'list' 或 'default'

// 获取当前访问的主机名（如 www.example.com）
const hostname = window.location.hostname;

// 解析主域名（去除所有子域名）
const domainParts = hostname.split('.');
let request_domain = hostname; // 默认是完整的 hostname

if (domainParts.length > 2) {
  // 只取最后两部分，如 example.com
  request_domain = domainParts.slice(-2).join('.');
}

// 处理 http/https 协议
const protocol = location.protocol === 'http:' ? 'http' : 'https';

// 导出 urlMode 变量
export { urlMode };

// 生成 API 地址
export default [
  `${protocol}://api.${request_domain}`,
  'https://api.hhhea.com',
  'https://api.hhheb.com',
  'https://api.hhhecfs.com',
  'https://api.hhhe.pw',
];

